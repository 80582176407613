(function (d, w) {
  "use strict"
  let forEach = [].forEach
  const body = d.getElementsByTagName('body')[0];

  const nav = d.getElementById('sitenav');
  const menu = d.getElementById('primary-menu');
  let hrefs = menu.querySelectorAll('a')
  let hrefsArr = Array.prototype.slice.call(hrefs)
  const bttn = d.getElementById('bttn-nav')

  let topNavs = menu.querySelectorAll('ul.menu > li > a')
  let sublinks = menu.querySelectorAll('.sub a')
  let lastNavLi = menu.querySelector('ul.menu > li:last-child')

  const srchButton = d.getElementById('bttn-srch')
  const srch = d.getElementById('srchblk');
  const srchForm = d.getElementById('srchform');
  const srchBox = srchform.querySelector('input');

  const wiw = w.innerWidth;
  const breakPoint = 1000;

  /* 
   remove any empty elements 
   */
  let entryContent = d.querySelector('.entry-content') || undefined;
  if (entryContent) {
    let rgx = /&nbsp;/g
    if (rgx.test(entryContent.innerHTML)) {
      entryContent.innerHTML = String(entryContent.innerHTML.replace(rgx, ' '))
    }
    entryContent.innerHTML = String(entryContent.innerHTML.replace(/[ ]{2,}/g, ' '))
  }

  const initialize = () => {
    srch.hidden = true;
    let timer = 0;
    if (wiw < breakPoint) {
      initializeTabIndices();
    } else {
      setSubTabIndices();
      bttn.setAttribute('tabindex', '-1')
    }
    /*
    initializeTabIndices();
    if (wiw >= breakPoint) {
      bttn.setAttribute('tabindex', '-1')
    }
    */
    bttn.setAttribute('aria-expanded', 'false')
    bttn.hidden = false
    // menu.hidden = true
    lastNavLi.classList.add('last')
    navSetup();
    bttnListener();
    topNavListener();
    /*
    hoverListener();
    */
    searchButtonListener();
    searchListener();
  }

  const openSlow = (el, speed) => {
    let timer = setTimeout(function () {
      el.classList.add('opened');
    }, speed);
  }

  const closeSlow = (el, speed) => {
    let timer = setTimeout(function () {
      el.classList.remove('opened');
    }, speed);
  }

  const clearT = () => {
    clearTimeout(timer);
  }

  const initializeTabIndices = () => {
    // hrefsArr.forEach(el => {
    forEach.call(hrefsArr, (el) => {
      el.setAttribute('tabindex', '-1');
    });
  }

  const setTabIndices = () => {
    // hrefsArr.forEach(el => {
    forEach.call(hrefsArr, (el) => {
      el.setAttribute('tabindex', '');
    });
  }

  const setTopTabIndices = () => {
    // hrefsArr.forEach(el => {
    forEach.call(topNavs, el => {
      el.setAttribute('tabindex', '');
    });
  }

  const setSubTabIndices = () => {
    // hrefsArr.forEach(el => {
    forEach.call(sublinks, (el) => {
      el.setAttribute('tabindex', '-1');
    });
  }



  const bttnListener =  () => {
    bttn.addEventListener('click', () => {
      let expanded = bttn.getAttribute('aria-expanded') === 'true' || false;
      bttn.setAttribute('aria-expanded', !expanded);
      menu.hidden = !menu.hidden;
      hideSubNavs();
      if (!expanded) {
        let fl = getFirstAndLastNavLinks()
        menu.focus()
        menu.classList.add('opened');
        setTopTabIndices();
        /*
        if (wiw < breakPoint) {
          setTabIndices();
        }
        */
        fl.last.addEventListener('keydown', (f) => {
          if (f.keyCode === 9 && !f.shiftKey) {
            f.preventDefault()
            bttn.focus()
          }
        })
        fl.first.addEventListener('keydown', (f) => {
          if (f.keyCode === 9 && f.shiftKey) {
            f.preventDefault()
            bttn.focus()
          }
        })
      } else {
        menu.classList.remove('opened');
        // if (wiw < breakPoint) {
          initializeTabIndices();
        // }
      }
    });
  }

  /**
   * get first and last navigation links.
   * used to send focus back to the toggle button after tabbing through the navigation list
   * return an object containing fn.first, fn.last
   */
  const getFirstAndLastNavLinks = () => {
    // let lastIdx = hrefsArr.length - 1
    return {
      'first': hrefsArr[0],
      'last': hrefsArr[hrefsArr.length - 1]
    }
  }

  /**
   * add event listener to all top-level navigation links that will:
   * toggle all hidden attributes and
   * reset the tabindex for that subnav
   */
  const topNavListener = () => {
    forEach.call(topNavs, (el) => {
      if (el.classList.contains('hassub') === true) {
        let prnt = el.parentNode;
        el.addEventListener('click', (e) => {
          e.preventDefault()
          let subnav = el.nextElementSibling
          let ariaHidden = subnav.getAttribute('aria-hidden') === 'true'
          if (ariaHidden) {
            setSubNavFocus(subnav.id)
          }
          if (el.classList.contains('opnd') === true) {
            el.classList.remove('opnd')
            setSubNavLinksTabIndex()
          } else {
            removeOpenedClass()
            el.classList.add('opnd')
          }
          subnav.setAttribute('aria-hidden', String(!ariaHidden))
          // subnav.hidden = !subnav.hidden;
        }, false)
          /*
        prnt.addEventListener('mouseover', () => {
          showSub(el)
        }, false)
        prnt.addEventListener('mouseout', () => {
          hideSub(el)
        }, false)
        */
      }
    })
  }

    /*
  const showSub = el => {
    let subnav = el.nextElementSibling
    let ariaHidden = subnav.getAttribute('aria-hidden') === 'true'
    if (ariaHidden) {
      setSubNavFocus(subnav.id)
    }
    el.classList.add('opnd')
    subnav.setAttribute('aria-hidden', String(!ariaHidden))
  }

  const hideSub = el => {
    let subnav = el.nextElementSibling
    let ariaHidden = subnav.getAttribute('aria-hidden') === 'true'
    el.classList.remove('opnd')
    setSubNavLinksTabIndex()
    subnav.setAttribute('aria-hidden', String(!ariaHidden))
  }

  const hoverListener = () => {
    forEach.call(topNavs, (el) => {
      if (el.classList.contains('hassub') === true && el.classList.contains('opnd') === false) {
        let prnt = el.parentNode;
        prnt.addEventListener('mouseover', () => {
          showSub(el)
        }, false)
        prnt.addEventListener('mouseout', () => {
          hideSub(el)
        }, false)
      }
    })
  }
  */

  /**
   * hide all opened subnavs.
   * reset tabindex to -1 on all subnav links.
   * set an empty tabindex for all links in the subnav that has just been opened.
   * set focus on that subnav.
   */
  const setSubNavFocus = (id) => {
    hideSubNavs()
    setSubNavLinksTabIndex()
    if (id) {
      let sn = document.getElementById(id)
      let subNavLinks = sn.querySelectorAll('a')
      forEach.call(subNavLinks, (snl) => {
        snl.setAttribute('tabindex', '')
      })
      sn.focus()
    }
  }

  /**
   * hides all subnavs
   * called from setSubNavFocus function
   */
  var hideSubNavs = () => {
    let submenus = menu.querySelectorAll('.sub')
    forEach.call(submenus, (el) => {
      // el.hidden = true
      el.setAttribute('aria-hidden', 'true')
    })
  }

  /**
   * removes an opnd class from all top-level links
   */
  var removeOpenedClass = () => {
    forEach.call(topNavs, (el) => {
      if (el.classList.contains('opnd') === true) {
        let subnav = el.nextElementSibling
        // console.log(el);
        el.classList.remove('opnd')
        subnav.setAttribute('aria-hidden', 'true');
      }
    })
  }

  const navSetup = () => {
    forEach.call(hrefs, (el) => {
      if (el.nextElementSibling) {
        // let subId = 'sub-' + el.textContent.replace(/ /g, '_').toLowerCase()
        let subId = 'sm' + hrefsArr.indexOf(el)
        let sub = el.nextElementSibling
        el.setAttribute('aria-haspopup', true)
        el.setAttribute('aria-controls', subId)
        if (sub.nodeName === 'UL') {
          el.classList.add('hassub')
          sub.setAttribute('aria-hidden', true)
          sub.hidden = false
            /*
          sub.hidden = true
            */
          sub.id = subId
        }
      }
      if (String(w.location) === el.href) {
        el.setAttribute('aria-current', 'page')
      }
        /*
      let parid = el.dataset.par
      if (el.getAttribute('aria-current') === 'page' && parid > 0) {
        let pgpar = document.getElementById('pg' + parid)
        if (pgpar.getAttribute('aria-current') !== 'location') {
          pgpar.setAttribute('aria-current', 'location')
        }
      }
    */
    })
  }

  /**
   * sets tabindex to -1 on all subnav links.
   */
  const setSubNavLinksTabIndex = () => {
    forEach.call(sublinks, (el) => {
      el.setAttribute('tabindex', -1)
    })
  }

  /** SETUP:
   * run a function that will set the tabindex to -1 on all subnav links.
   */
  // setSubNavLinksTabIndex()

  const searchButtonListener = () => {
    srchButton.addEventListener('click', () => {
      const expanded = srchButton.getAttribute('aria-expanded') === 'true' || false;
      srchButton.setAttribute('aria-expanded', !expanded);
      srch.hidden = !srch.hidden;
      if (!expanded) {
        body.classList.add('noscroll');
        srchBox.focus();
        openSlow(srch, 100);
      } else {
        body.classList.remove('noscroll');
        clearT();
        srch.classList.remove('opened');
      }
    });
  }

  const searchListener = () => {
    let button = srchForm.querySelector('button');
    srch.addEventListener("click", e => {
      if (e.target !== button && e.target !== srchBox) {
        closeOverlay(srch, srchButton, true);
      }
    }, true);
    button.addEventListener('keydown', e => {
      if (e.keyCode === 9 && !e.shiftKey) {
        e.preventDefault();
        closeOverlay(srch, srchButton, true);
      }
    });
    srchBox.addEventListener("keydown", e => {
      if (e.keyCode === 9 && e.shiftKey) {
        e.preventDefault();
        closeOverlay(srch, srchButton, true);
      }
    });
  }

  const closeOverlay = (el, elButton, focused) => {
    if (focused) {
      elButton.focus();
    }
    body.classList.remove('noscroll');
    closeSlow(el, 100);
    el.hidden = !el.hidden;
    elButton.setAttribute('aria-expanded', false);
  }

  initialize();

  /*
  buttons.forEach(el => {
    el.addEventListener('click', () => {
      const ctrls = d.getElementById(el.getAttribute('aria-controls'));
      const isOpen = ctrls.classList.contains('opened');
      if (isOpen) {
        ctrls.classList.remove('opened');
      } else {
        closeAll();
        ctrls.classList.add('opened');
        body.classList.add('noscroll');
        ctrls.focus();
        const closer = ctrls.querySelector('.close');
        closer.addEventListener("click", () => {
          ctrls.classList.remove("opened");
          body.classList.remove("noscroll");
        });
      }
    });
  });

  const closeAll = () => {
    body.classList.remove('noscroll');
    let opened = [...d.querySelectorAll('.opened')];
    opened.forEach(el => {
      el.classList.remove('opened');
    });
  }
  */
}(document, window));
